
























import LazyHydrate from 'vue-lazy-hydration';
import { useRoute, defineComponent } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import AppHeader from '~/components/AppHeader.vue';
import Logo from '~/components/HeaderLogo.vue';
import BottomNavigation from '~/components/BottomNavigation.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import TopBar from '~/components/TopBar/TopBar.vue';
import HeaderNavigation from "~/components/Header/Navigation/HeaderNavigation.vue";

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    LoadWhenVisible,
    LazyHydrate,
    HeaderNavigation,
    AppHeader,
    BottomNavigation,
    IconSprite,
    // TopBar,
    AppFooter: () => import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    WishlistSidebar: () => import(/* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'),
    LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/LoginModal.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },
  head() {
    return {
      title: 'H&H Industrial Products | We Bring You Quality Industrial Tools',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Wide selection of tooling and accessories for the manufacturing and metalworking industries. We offer quality inspection, measuring, cutting, power, and hand tools.'
        }
      ]
    }
  },
  setup() {
    const route = useRoute();
    const {
      isCartSidebarOpen, isWishlistSidebarOpen, isLoginModalOpen, toggleLoginModal,
    } = useUiState();
    
    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
    };
  },
});
