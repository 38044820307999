









import {
  defineComponent,
  PropType,
  ref,
  toRefs,
} from "@nuxtjs/composition-api";

import { CategoryTree } from "~/modules/GraphQL/types";
import VMenuMultiLevel from "../menu/components/v-menu-multi-level.vue";
export default defineComponent({
  name: "HeaderNavigation",
  components: {
    VMenuMultiLevel
  },
  props: {
    categoryTree: {
      type: Array as PropType<CategoryTree[]>,
      default: () => [],
    },
  },
  setup(props) {
    const currentCategory = ref<CategoryTree | null>(null);
    const setCurrentCategory = (category: CategoryTree | null) => {
      currentCategory.value = category;
    };
    return {
      setCurrentCategory,
      currentCategory,     
    };
  },
});
