import { render, staticRenderFns } from "./HeaderLogoVue.vue?vue&type=template&id=000c4be3&scoped=true"
import script from "./HeaderLogoVue.vue?vue&type=script&lang=ts"
export * from "./HeaderLogoVue.vue?vue&type=script&lang=ts"
import style0 from "./HeaderLogoVue.vue?vue&type=style&index=0&id=000c4be3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "000c4be3",
  null
  
)

export default component.exports