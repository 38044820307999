













import { SfImage } from "@storefront-ui/vue";
import { defineComponent } from "@nuxtjs/composition-api";
import SvgImage from "~/components/General/SvgImage.vue";

export default defineComponent({
  name: "HeaderLogo",
  components: { SvgImage, SfImage },
  setup() {},
});
