
if (process.client) {
  navigator.serviceWorker.register('/service_worker.js', { scope: '/' })
    .then(registration => {
      registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
    })
    .catch(error => {
      // console.error('Service Worker registration failed:', error);
    });
} 
